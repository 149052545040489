import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import FestivalProgramme from './FestivalProgramme'

const PrismicFestivalProgrammes = (props) => {

	const showDownloadMessage = props.showDownloadMessage && props.showDownloadMessage === true

	const data = useStaticQuery(graphql`{
		programmes: allPrismicFestivalProgramme (sort:{fields:data___programme_order}) {
			nodes {
				last_publication_date
				data {
					programme_type
					programme_name {
						text
					}
					programme_file {
						url
					}
					programme_image {
						gatsbyImageData(width: 500, placeholder: DOMINANT_COLOR, layout: CONSTRAINED)
					}
				}
			}
		}
	}`)

	const programmes = data.programmes.nodes

	return (
		<>
			<div className={`flex items-start justify-around`}>
				{programmes.map((item, index) => {
					return (
						<FestivalProgramme
							key={item.data.programme_name.text}
							image={item.data.programme_image}
							name={item.data.programme_name.text}
							link={item.data.programme_file.url}
						/>
					)
				})}
			</div>
			{ showDownloadMessage && <p className={`text-lg mt-4`}>Download the programmes to see the full list of events including <strong>Festival Plus+</strong></p> }
		</>
	)
}

export default PrismicFestivalProgrammes
