import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

export default function PrismicSponsorFeature(props) {

	const data = useStaticQuery(graphql`{
		sponsorFeature: prismicSponsorFeature {
			data {
				sponsor_feature_title {
					text
				}
				sponsor_feature_subtitle {
					text
				}
				sponsor_feature_content {
					html
				}
				sponsor_feature_link {
					url
				}
				sponsor_feature_link_text {
					text
				}
				sponsor_feature_image {
					gatsbyImageData(width: 500, placeholder: DOMINANT_COLOR, layout: CONSTRAINED)
				}
			}
		}
	}`)

	const feature = data.sponsorFeature?.data

	return feature ? (
		<section className="pt-4 pb-8 md:p-8 md:pb-12 bg-white md:flex md:items-start md:flex-row-reverse">
			<div className={`md:w-1/2 p-4`}>
				<h2 className={`text-4xl font-heading font-bold mb-8`}>{feature.sponsor_feature_title.text}</h2>
				<p className={`lg:text-lg xl:text-xl 2xl:text-2xl leading-relaxed font-bold px-4 pb-4 border-l-8 border-black`}>{feature.sponsor_feature_subtitle.text}</p>
				<div
					className={`lg:text-lg xl:text-xl 2xl:text-2xl leading-relaxed px-4 pb-4 border-l-8 border-black`}
					dangerouslySetInnerHTML={{ __html: feature.sponsor_feature_content.html}}
				/>
				<p className={`lg:text-lg xl:text-xl 2xl:text-2xl leading-relaxed px-4 pb-4 border-l-8 border-black`}>
					<a
						href={feature.sponsor_feature_link.url}
						target={`_blank`}
						rel={`noopener nofollow`}
						className={`text-bbf-yellow border-b-2 border-transparent hover:border-bbf-yellow transition-all`}
					>{feature.sponsor_feature_link_text.text}</a>
				</p>
			</div>
			<div className={`md:w-1/2 p-4 flex items-center justify-center`}>
				<a
					href={feature.sponsor_feature_link.url}
					target={`_blank`}
					rel={`noopener nofollow`}
					className={`pr-4`}
				>
					<GatsbyImage
						image={feature.sponsor_feature_image.gatsbyImageData}
						alt={`Baillie Gifford Trust Magazine Cover`}
						objectFit={`cover`}
						className={`shadow-md transform hover:scale-105 hover:shadow-lg transition-all`} />
				</a>
			</div>
		</section>
	) : null
}
