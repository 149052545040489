import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

export default function ChildrensFestival(props) {

	const data = useStaticQuery(graphql`{
		image: file(relativePath: {eq: "general/Bozzy-16-9-011.jpg"}) {
			childImageSharp {
				gatsbyImageData(width: 1000, placeholder: DOMINANT_COLOR, layout: CONSTRAINED)
			}
		}
	}`)

	return (
		<section className="pt-4 pb-8 md:p-8 md:pb-12 bg-gradient-to-br from-bbf-light-blue to-bbf-teal md:flex md:items-start md:flex-row-reverse">
			<div className={`md:w-1/2 p-4`}>
				<h2 className={`text-4xl font-heading font-bold text-white mb-8`}>Children's Festival</h2>
				<p className={`lg:text-lg xl:text-xl 2xl:text-2xl text-white leading-relaxed font-bold px-4 pb-4 border-l-8 border-blue-500`}>Why is it so important to children to tell our stories?</p>
				<p className={`lg:text-lg xl:text-xl 2xl:text-2xl text-white leading-relaxed px-4 pb-4 border-l-8 border-blue-500`}>Stories connect the past and present to the future and learning these stories is what can awaken future generations to their potential.</p>
				<p className={`lg:text-lg xl:text-xl 2xl:text-2xl text-white leading-relaxed px-4 pb-4 border-l-8 border-blue-500`}>Through the Festival, the Boswell Trust engages with primary and secondary schools across Ayrshire and beyond. </p>
				<p className={`lg:text-lg xl:text-xl 2xl:text-2xl text-white leading-relaxed px-4 pb-4 border-l-8 border-blue-500`}>
					<Link to={`/childrens-festival`} className={`text-bbf-yellow border-b-2 border-transparent hover:border-bbf-yellow transition-all`}>Find out more and view highlights</Link>
				</p>
				{/*<p className={`text-lg text-white leading-relaxed px-4 border-l-8 border-blue-500`}>Schools - please register <a href={`https://boswellbookfestival.us6.list-manage.com/subscribe?u=877a9fa5e1&id=107d3caedc`} className={`text-bbf-yellow border-b-2 border-transparent hover:border-bbf-yellow transition-all`}>here</a> for the Schools Festival</p>*/}

			</div>
			<div className={`md:w-1/2 p-4 flex items-center justify-center`}>
				{/*<EmbedVideo src={`https://www.youtube-nocookie.com/embed/lBRFI2UH3lQ`} title={ `2021 Children's Festival Promo` } />*/}
				<Link to={`/childrens-festival`}>
					<GatsbyImage
						image={data.image.childImageSharp.gatsbyImageData}
						alt={`Boswell Book Festival Children's Festival image`}
						objectFit={`cover`}
						className={`shadow-md transform hover:scale-105 hover:shadow-lg transition-all`} />
				</Link>
			</div>
		</section>
	)
}
