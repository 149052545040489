import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image";

const BannerImageWithOverlay = (props) => {
	const { image, children } = props
	return (
		<div className={`border-b-4 border-bbf-dark-green bg-gradient-to-br from-bbf-pink to-bbf-mid-green-alt relative object-cover`}>
			<GatsbyImage image={image} className={``} alt={`Page banner image`} />
			<div className={`lg:absolute lg:bottom-0 lg:left-0 lg:right-0 lg:z-10`}>{children}</div>
		</div>
	)
}

export default BannerImageWithOverlay
