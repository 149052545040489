import React from 'react'

export default function TicketsInfo(props) {

	const { festivalYear, postFestival } = props

	if (postFestival) {
		return (
			<>
				<h3 className={`text-2xl xl:text-3xl font-bold mb-6`}>Boswell Book Festival events are now over for {festivalYear}, except for schools’ events which are available to view on demand until 30 June</h3>
				<div className={`text-xl xl:text-2xl`}>
					<p className={`mb-4`}>The online schools' events are free. Registration required.</p>
				</div>
			</>
		)
	}
	return (
		<>
			<h2 className={`text-3xl xl:text-4xl mb-4`}>Tickets for the {festivalYear} Boswell Book Festival</h2>
			{/*<p className={`text-2xl text-bbf-red mb-4`}>Until Thursday 23 March, tickets are only available to members of our Friends and Patrons scheme.</p>*/}
			<h3 className={`text-2xl xl:text-3xl font-bold mb-4`}>Main festival events will be live at Dumfries House with the exception of Rory Stewart and Esther Rutter on Friday. Tickets range from £10 to £12.</h3>
			<p className={`text-xl xl:text-2xl mb-4`}>Children’s festival events are live at the weekend and cost £3, or £2 for KA18 postcode addresses.</p>
			<div className={`text-lg xl:text-xl `}>
				<p>Events in the main venues will also be live-streamed.</p>
				<p>Online tickets are £5 per event or £35 for a Rover Pass giving access to all online events.</p>
				<p>You will receive the links needed to access online events via the e-ticket that will be emailed to you.</p>
			</div>
		</>
	)
}



