import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image";
import SwiperCore, { Autoplay, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css'

SwiperCore.use([Autoplay, Pagination, Scrollbar, A11y])

const renderSlide = (image, index) => {
	return (
		<SwiperSlide key={index}>
			<GatsbyImage image={image} objectFit="cover" className={`max-h-screen-60`} alt={`Slide ${index+1}`} />
		</SwiperSlide>
    );
}

const HeroSwiper = (props) => {

	return (
		<Swiper
			spaceBetween={0}
			slidesPerView='auto'
			centeredSlides={true}
			autoplay={{delay: 5000, disableOnInteraction: false}}
			pagination={{ clickable: true }}
			// scrollbar={{ draggable: true }}
			onSwiper={(swiper) => {}}
			onSlideChange={() => {}}
			className="max-h-screen-60"
		>
			{props.images.map((image, index) => {
				return renderSlide(image.childImageSharp.gatsbyImageData, index);
			})}
		</Swiper>
    );
}

export default HeroSwiper
