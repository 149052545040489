import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

export default function FestivalProgramme(props) {
	const { name, image, link } = props

	return (
		<a
			href={link}
			title={`Download ${name}`}
			target={`_blank`}
			rel={`noopener noreferrer`}
		>
			<GatsbyImage
				image={image.gatsbyImageData}
				alt={name}
				objectFit={`contain`}
				className={`w-24 lg:w-32 xl:w-40 2xl:w-48 shadow-md transform hover:scale-105 hover:shadow-lg transition-all`} />
		</a>
	)
}
