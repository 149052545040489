import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import BannerImageWithOverlay from '../BannerImageWithOverlay'
import PrismicFestivalProgrammes from '../../Event/PrismicFestivalProgrammes'
import FTEventList from '../../FTEvent/FTEventList'
import TicketsInfo from '../../TicketsInfo'

export default function HomeForFestival() {

	const data = useStaticQuery(graphql`{
		site {
			siteMetadata {
				title
				config {
					boxOfficeEnabled
					festivalYear
				}
			}
		}
		homepageBannerImg: file(relativePath: {eq: "general/Bozzy-16-9-011.jpg"}) {
			childImageSharp {
				gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH)
			}
		}
		mainFestivalEvents: allFutureTicketingEvent(filter: {category: {eq:"main"}, datetime: {gt:"2024-01-01 00:00", lt:"2025-01-01 00:00 "}}, sort:{fields: datetime, order:ASC}) {
			nodes {
				id
				ft_id
				name
				uuid
				datetime
				date:datetime(formatString:"YYYY-MM-DD")
				time:datetime(formatString:"h:mm a")
				description
				eticket_content
				ticket_content
				valid
				img_path
				local_event_img {
					childImageSharp {
						gatsbyImageData(placeholder: DOMINANT_COLOR, layout: CONSTRAINED)
					}
				}
			}
		}
		childrensFestivalEvents: allFutureTicketingEvent(filter: {category: {eq:"childrens"}, datetime: {gt:"2024-01-01 00:00", lt:"2025-01-01 00:00 "}}, sort:{fields: datetime, order:ASC}) {
			nodes {
				id
				ft_id
				name
				uuid
				datetime
				date:datetime(formatString:"YYYY-MM-DD")
				time:datetime(formatString:"h:mm a")
				description
				eticket_content
				ticket_content
				valid
				img_path
				local_event_img {
					childImageSharp {
						gatsbyImageData(placeholder: DOMINANT_COLOR, layout: CONSTRAINED)
					}
				}
			}
		}
		schoolsFestivalEvents: allFutureTicketingEvent(filter: {category: {eq:"schools"}, datetime: {gt:"2024-01-01 00:00", lt:"2025-01-01 00:00 "}}, sort:{fields: datetime, order:ASC}) {
			nodes {
				id
				ft_id
				name
				uuid
				datetime
				date:datetime(formatString:"YYYY-MM-DD")
				time:datetime(formatString:"h:mm a")
				description
				eticket_content
				ticket_content
				valid
				img_path
				local_event_img {
					childImageSharp {
						gatsbyImageData(placeholder: DOMINANT_COLOR, layout: CONSTRAINED)
					}
				}
			}
		}
	}`)

	const { boxOfficeEnabled, festivalYear } = data.site.siteMetadata.config

	return (
		<>
			<div className="bg-gradient-to-br from-bbf-pink to-bbf-mid-green-alt border-b-4 border-bbf-dark-green mn-4 md:mb-8">
				<BannerImageWithOverlay image={data.homepageBannerImg.childImageSharp.gatsbyImageData}>
					<section className={`flex items-start flex-wrap bg-bbf-mid-green bg-opacity-95 text-white`}>
						<div className={`p-4 md:p-12 md:w-1/2`}>
							<h2 className={`text-4xl xl:text-5xl font-bold`}>{festivalYear} Boswell Book Festival, Dumfries House, Ayrshire</h2>
							<div className={`divider h-6`} />
							<h3 className={`text-2xl xl:text-3xl`}>Click on the images on the right to enjoy the full programmes</h3>
							<div className={`divider h-6`} />
							<h3 className={`text-2xl xl:text-3xl`}>To book an event now scroll down to Tickets</h3>
							{/*<h4 className={`text-xl xl:text-2xl`}>To hear Bill Paterson's Boswell readings, watch the Portraits as Biography events or see Dr Gordon Turnbull's full talk on the life of Boswell <Link to={`/2021/festival-plus`} className={`link link-yellow`}>click here</Link>.</h4>*/}
						</div>
						<div className="p-4 md:p-12 w-full md:w-1/2">
							<PrismicFestivalProgrammes />
							{/*<EmbedVideo src={`https://www.youtube-nocookie.com/embed/UqHrEISYT4w`} title={`2021 Festival Highlights`} />*/}
						</div>
					</section>
				</BannerImageWithOverlay>
			</div>

			{/* Box Office section */}
			{ boxOfficeEnabled ? (
				<section className="flex flex-row items-start flex-wrap md:pb-16 bg-boswell-handwriting">
					{/* Event list */}
					<div className={`p-4 md:p-12 md:pb-16`}>
						<div className={`mb-12`}>
							<TicketsInfo festivalYear={festivalYear} postFestival={false} />
						</div>
						<FTEventList
							data={{
								main: data.mainFestivalEvents.nodes,
								childrens: data.childrensFestivalEvents.nodes,
								schools: data.schoolsFestivalEvents.nodes
							}}
							festivalType={`main`}
							festivalYear={festivalYear}
							showDayFilter={true}
							showEventDate={true}
							showTypeFilter={true}
							showAllEventsOption={true}
						/>
					</div>
				</section>
			) :  null }
		</>
	)
}
