import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout/Layout'
import AboutTheFestival from '../components/Page/Homepage/AboutTheFestival'
import ChildrensFestival from '../components/Page/Homepage/ChildrensFestival'
import Donations from '../components/Page/Homepage/Donations'
import PrismicSponsorFeature from '../components/Page/Homepage/PrismicSponsorFeature'
import HomeForFestival from "../components/Page/Homepage/HomeForFestival";
import HomeForRestOfYear from "../components/Page/Homepage/HomeForRestOfYear";

const IndexPage = ({ data }) => {

	const { boxOfficeEnabled, boxOfficePresale } = data.site.siteMetadata.config

	return (
		<Layout>
			{/* Section 1 */}
			{ boxOfficeEnabled && !boxOfficePresale ? <HomeForFestival /> : <HomeForRestOfYear /> }
			{/* Section 2 */}
			<AboutTheFestival />
			{/* Section 3 */}
			<ChildrensFestival />
			{/* Section 4 */}
			<Donations />
			{/* Section 5 */}
			<PrismicSponsorFeature />
		</Layout>
	)
}

export const query = graphql`{
	site {
		siteMetadata {
			title
			config {
				boxOfficeEnabled
				boxOfficePresale
			}
		}
	}
}`

export default IndexPage
