import React from 'react'
import { Helmet } from 'react-helmet'

const FacebookFeed = () => {
	return (
		<>
			<Helmet>
				<script async defer crossOrigin="anonymous" src="https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v18.0" nonce="J9zQYORz"></script>
			</Helmet>
			<div id="fb-root" className={`w-full flex justify-center`}>
				<div
					className="fb-page"
					data-href="https://www.facebook.com/boswellbookfestival"
					data-tabs="timeline"
					data-width=""
					data-height=""
					data-small-header="false"
					data-adapt-container-width="false"
					data-hide-cover="false"
					data-show-facepile="true"
				>
					<blockquote
						cite="https://www.facebook.com/facebook"
						className="fb-xfbml-parse-ignore">
						<a href="https://www.facebook.com/facebook">
							Facebook
						</a>
					</blockquote>
				</div>
			</div>
		</>
	)
}

export default FacebookFeed
