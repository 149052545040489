import React from 'react'
import PaypalDonateFormCard from '../../Form/PaypalDonateFormCard'


export default function Donations(props) {

	return (
		<section className="pt-4 pb-8 md:p-8 md:pb-12 bg-gradient-to-br from-bbf-orange-1 to-bbf-yellow md:flex md:items-start">
			<div className={`md:w-1/2 p-4`}>
				<h2 className={`text-4xl font-heading font-bold text-bbf-dark-green mb-8`}>If you wish to support the festival going forwards, please use the donate button.</h2>
				{/*<h3 className={`text-2xl font-heading text-bbf-dark-green mb-6`}>If you wish to support the festival going forwards, please use the donate button.</h3>*/}
			</div>
			<div className={`md:w-1/2 p-4`}>
				<PaypalDonateFormCard
					title={`Donate`}
					description={`We are indebted to all who have supported the Boswell Book Festival over the past ten years in so many different ways. It is only with the help of so many loyal supporters that the Festival has achieved recognition as a cultural event of national importance.`}
					headerClassName={`bg-bbf-yellow`}
				/>
			</div>
		</section>
	)
}
