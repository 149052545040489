import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"

const PrismicLatestNews = () => {
	const data = useStaticQuery(graphql`{
		latestNews: prismicLatestNews {
			data {
				latest_news_title {
					text
				}
				latest_news_subtitle {
					text
				}
				latest_news_excerpt {
					text
				}
				latest_news_event_link {
					url
					slug
				}
				latest_news_read_more_link {
					url
					slug
				}
				latest_news_date
				latest_news_content {
					text
					html
				}
				latest_news_featured_image {
					gatsbyImageData
				}
				show_on_homepage
			}
		}
	}`)

	const news = data.latestNews.data
	const featuredImage = news.latest_news_featured_image?.gatsbyImageData

	return (
		<section className="p-4 md:pl-12 md:flex md:items-start md:flex-row-reverse">
			<div className="flex-col items-start markdown-content ">
				<h2 className="font-bold">{news.latest_news_title.text}</h2>
				<h3 className="">{news.latest_news_subtitle.text}</h3>
				{ news.latest_news_excerpt.text.length ? ( <h4 className="">{news.latest_news_excerpt.text}</h4> ) : '' }
				<div className="" dangerouslySetInnerHTML={{ __html: news.latest_news_content.html }}></div>
			</div>
			<div className="w-full mb-4 md:w-1/3 md:mr-8 md:shrink-0 md:flex-col md:items-center">
				{ featuredImage && <GatsbyImage image={featuredImage} className={`shadow-lg mx-auto`} alt={`Latest News featured image`} /> }
			</div>
		</section>
	)
}

export default PrismicLatestNews
