import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import HeroSwiper from '../../Page/HeroSwiper'
import PrismicLatestNews from './PrismicLatestNews'
// import TwitterFeed from '../../Social/TwitterFeed'
// import EmbedVideo from '../../EmbedVideo'
import FacebookFeed from '../../Social/FacebookFeed'

export default function HomeForRestOfYear() {

	const data = useStaticQuery(graphql`{
		site {
			siteMetadata {
				title
				config {
					boxOfficeEnabled
					festivalYear
				}
			}
		}
		swiper: allFile(filter: {relativeDirectory: {eq: "swiper"}}, sort:{fields:name}) {
			nodes {
				childImageSharp {
					gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH)
				}
			}
		}
	}`)

	return (
		<>
			<div className="bg-gradient-to-br from-bbf-pink to-bbf-mid-green-alt border-b-4 border-bbf-dark-green mn-4 md:mb-8">
				<HeroSwiper images={data.swiper.nodes} />
			</div>

			{/* latest news and twitter feed section */}
			<section className="flex flex-row items-start flex-wrap md:pb-16 bg-boswell-handwriting">
				{/* Latest news section */}
				<div className="w-full md:w-2/3 xl:w-1/2">
					<PrismicLatestNews />
				</div>

				{/* Festival Highlights */}
				<div className="w-full md:w-1/3 xl:w-1/2 p-4 md:pr-12">
					{/*<EmbedVideo src={`https://www.youtube-nocookie.com/embed/82POI2HhYM8`} title={`2022 Festival Highlights`} />*/}
					{/*<TwitterFeed />*/}
					<FacebookFeed />
				</div>
			</section>
		</>
	)
}
