import React from 'react'

const EmbedVideo = (props) => {
	const { title, src, height, width, containerClassName, iframeClassName } = props
	return (
		<div className={`embedVideo-container ${containerClassName ? containerClassName : ''}`}>
			<iframe
				title={ title ? title : 'Embedded video content' }
				width={ width ? width : 560 }
				height={ height ? height : 316 }
				src={ src }
				className={`embedVideo-iframe border-0 ${iframeClassName ? iframeClassName : ''}`}
				loading="eager"
				allowFullScreen=""
				sandbox="allow-same-origin allow-scripts allow-popups allow-presentation" />
		</div>
	)
}

export default EmbedVideo
